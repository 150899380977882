<template>
  <div>
    <button @click="downloadJson()">download</button>
  </div>
</template>

<script>
import en from "../lang/translations/id/index";
import id from "../lang/translations/en/index";

export default {
  methods: {
    downloadJson() {
      
      //   Convert JSON Array to string.
      var json = JSON.stringify({ en, id });

      //Convert JSON string to BLOB.
      json = [json];
      var blob1 = new Blob(json, { type: "text/plain;charset=utf-8" });

      //Check the Browser.
      var isIE = false || !!document.documentMode;
      if (isIE) {
        window.navigator.msSaveBlob(blob1, "Customers.txt");
      } else {
        var url = window.URL || window.webkitURL;
        const link = url.createObjectURL(blob1);
        var a = document.createElement("a");
        a.download = "Translate.json";
        a.href = link;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    },
  },
};
</script>

<style></style>
